<template>
  <div>
    <div class="div-form">
      <Messages />
      <SendConfirmMailMobile v-if="showSendConfirmMailMobile" typeConfirm="email" :email="login.email" />
      <div class="div-title">
        <span class="title-login"> Login de acesso</span>
        <br />
      </div>
      <b-row>
        <b-col sm="12">
          <InputText title="Usuário" field="userName" :formName="formName" :required="true" :maxLength="50"
            v-model="login.userName">
            <div class="glyphicon">
              <i class="fas fa-user"></i>
            </div>
          </InputText>
          <InputPassword title="Senha" field="password" :formName="formName" :required="true" :maxLength="50"
            v-model="login.password">
            <div class="glyphicon">
              <i class="fas fa-lock"></i>
            </div>
          </InputPassword>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col md="6">
          <Button _key="sigin" type="primary" title="Entrar" :disabled="!isFormValid(formName)"
            classIcon="fad fa-sign-out-alt" size="medium" :clicked="sigin" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputPassword from "@nixweb/nixloc-ui/src/component/forms/InputPassword";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Messages from "@nixweb/nixloc-ui/src/component/shared/Messages.vue";

import SendConfirmMailMobile from "./SendConfirmMailMobile.vue";

import Login from "@/components/modules/adm/login/Login.js";
import Menu from "@/components/modules/adm/login/Menu.js";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "Login",
  components: {
    InputText,
    Button,
    InputPassword,
    Messages,
    SendConfirmMailMobile,
  },
  data() {
    return {
      formName: "login",
      login: new Login(),
      menu: new Menu(),
      urlLogin: "api/v1/adm/auth/login",
      showSendConfirmMailMobile: false,
    };
  },
  created() {
    this.addUserLogged({});
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapState("user", ["userLogged"]),
    ...mapState("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["removeLoading"]),
    ...mapMutations("user", ["addUserLogged", "addItemMenu", "removeItemMenu"]),
    sigin() {
      let params = { url: this.urlLogin, obj: this.login };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.addUserLogged(response.content);
          this.loadMenu();

          if (!localStorage.codeBrowser)
            localStorage.setItem("codeBrowser", this.generateCode(5));

          this.$router.replace(this.$route.query.redirect || "/dashboard");
        } else {
          if (response.content) this.showSendConfirmMailMobile = true;
          this.login.email = response.content;
        }
        this.removeLoading(["sigin"]);
      });
    },
    loadMenu() {
      this.removeItemMenu();
      let self = this;
      this.userLogged.user.rules.forEach(function (rule) {
        self.hasRule(rule);
      });
    },
    hasRule(rule) {
      let self = this;
      this.menu.itens.forEach(function (item) {
        if (rule.name === item.rule) self.addItemMenu([item]);
      });
    },
    generateCode(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "stopSendConfirmEmailMobile") {
          this.showSendConfirmMailMobile = false;
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-form {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

.div-title {
  margin-bottom: 30px;
}

.title-login {
  font-size: 25px;
}
</style>
